import { NotificationsPlugin } from "@pimo/notifications-plugin";
import type { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import {
  TwoColumnGridLayout,
  TwoColumnGridLayoutProps,
} from "@pimo/pimo-components";
import { OESettingsPayload } from "crq-types";

import { CRQAppState } from "../app";
import { GeneralSettingsPage } from "../components/settings/general-settings";
import { ManageOEsSettingsPage } from "../components/settings/ManageOEs-settings-page";
import { APP_ROUTES } from "../constants";
import { fetchOEs } from "../helpers/fetch-helpers";
import {
  fetchOEOverview,
  fetchProgram,
  updateProgramReportingDate,
} from "../helpers/fetch-helpers";
import {
  fetchGlobalSetting,
  updateGlobalSetting,
} from "../helpers/global-setting";
import { createOE, deleteOEs, updateOE } from "../helpers/OE";

// eslint-disable-next-line
export interface SettingsPluginState {}

export class SettingsPlugin
  implements Plugin<CRQAppState, SettingsPluginState, "viewname">
{
  route?: Route<"viewname", CRQAppState>;
  private settingsView?: View<CRQAppState, TwoColumnGridLayoutProps>;
  private app?: App<CRQAppState>;

  onRegister(app: App<CRQAppState>): void {
    this.app = app;

    const entries = [
      {
        link: "/settings/manage-OEs",
        title: "Manage OEs",
        viewname: "manage-OEs",
      },
      {
        link: "/settings/notifications",
        title: "Notifications",
        viewname: "notifications",
      },
      {
        link: "/settings/notifications-scheduled",
        title: "Scheduled Notifications",
        viewname: "scheduled-notifications",
      },
      {
        link: "/settings/general",
        title: "General Settings",
        viewname: "general",
      },
    ];

    this.settingsView = app.createView({
      name: "Settings",
      layout: new TwoColumnGridLayout({
        spacing: 1,
        enablePageBreak: false,
        workspaceRoute: APP_ROUTES.overview,
        entries,
        title: "Settings",
      }),
    });

    this.buildRoute();

    this.buildManageOEsView();
    this.buildNotificationsView();
    this.buildScheduledNotificationsView();
    this.buildGeneralSettingsView();
  }

  private buildRoute() {
    if (!this.app || !this.settingsView) return;

    this.route = this.app.createRoute<"viewname">({
      path: "/settings/:viewname",
      view: this.settingsView,
    });

    this.route.on("load", async () => {
      if (!this.app) {
        return;
      }

      if (this.app?.getAppState().userProfile?.role !== "admin") {
        this.app?.navigate(APP_ROUTES.overview);
      }

      const globalSetting = await fetchGlobalSetting();

      this.app?.patchAppState({
        isSidebarOpen: false,
        OEs: await fetchOEs(),
        settings: {
          hasActivationCheckForUpdate:
            globalSetting?.hasActivationCheckForUpdate ?? true,
        },
      });
    });
  }

  private buildManageOEsView() {
    const manageOEsView = this.settingsView?.addComponent({
      component: ManageOEsSettingsPage,
      layoutProps: { viewname: "manage-OEs", xs: 12 },
    });

    manageOEsView?.mapState((appState) => {
      return {
        OEs: appState.OEs ?? [],
      };
    });

    manageOEsView?.on("OECreated", async (event) => {
      const payload = event.payload as OESettingsPayload;
      if (!payload) return;

      await createOE(payload);

      this.app?.patchAppState({
        OEs: await fetchOEs(),
      });
    });

    manageOEsView?.on("OEUpdated", async (event) => {
      const payload = event.payload as OESettingsPayload;
      if (!payload || !payload.id) return;

      await updateOE(payload.id, payload);
      this.app?.patchAppState({
        OEs: await fetchOEs(),
      });
    });
    manageOEsView?.on("OEDeleted", async (event) => {
      const payload = event.payload as OESettingsPayload;
      if (!payload || !payload.id) return;
      await deleteOEs(payload.id);
      this.app?.patchAppState({
        OEs: await fetchOEs(),
      });
    });
  }

  private buildNotificationsView() {
    if (!this.settingsView || !this.route) return;

    const notificationsPlugin = this.app?.getPluginByName<
      NotificationsPlugin<CRQAppState>
    >("NotificationsPlugin");

    notificationsPlugin?.renderNotificationsManager({
      view: this.settingsView,
      route: this.route as Route,
      layoutProps: { viewname: "notifications", xs: 12 },
    });
  }

  private buildScheduledNotificationsView() {
    if (!this.settingsView || !this.route || !this.app) return;

    const notificationsPlugin = this.app.getPluginByName<
      NotificationsPlugin<CRQAppState>
    >("NotificationsPlugin");

    notificationsPlugin?.renderNotificationsManager({
      view: this.settingsView,
      route: this.route as Route,
      layoutProps: { viewname: "notifications-scheduled", xs: 12 },
      notificationType: "scheduled",
    });
  }

  private buildGeneralSettingsView() {
    const generalSettingsView = this.settingsView?.addComponent({
      component: GeneralSettingsPage,
      layoutProps: { viewname: "general", xs: 12 },
    });

    generalSettingsView?.mapState((state) => {
      return {
        hasActivationCheckForUpdate:
          state.settings?.hasActivationCheckForUpdate ?? true,
        reportingDate: state.program?.reportingDate ?? "",
      };
    });

    generalSettingsView?.on("general-settings-changed", async ({ payload }) => {
      if (!payload || !this.app) return;

      await Promise.all([
        updateProgramReportingDate(payload.reportingDate),
        updateGlobalSetting({
          hasActivationCheckForUpdate: payload.hasActivationCheckForUpdate,
        }),
      ]);

      const [OEs, OEOverview, program] = await Promise.all([
        fetchOEs(),
        fetchOEOverview(),
        fetchProgram(),
      ]);

      this.app.patchAppState({
        OEs: OEs ?? [],
        OEOverview,
        program,
      });
    });
  }
}
