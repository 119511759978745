import { Box, styled, Typography, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import {
  Card,
  CardContent,
  type CardContentProps,
  type CardProps,
} from "../../molecules";

export interface BarChartCardProps {
  /** Global Title of the Card, displayed to the left */
  title: string;
  /** Value of series */
  series: number[];
  /** Average value of approved budget per month (optional) */
  average?: number;
  /** List of months of half year */
  categories: string[];
  /** Legend metric label for columns */
  seriesLabel: string;
  /** Legend metric label for line (optional) */
  averageLabel?: string;
  /** (optional) adds a currency next to numbers */
  currency?: string;
  /** (optional) more styling props */
  cardProps?: CardProps;
  /** (optional) more styling props */
  cardContentProps?: CardContentProps;
  /** (optional) minimum value for the y-axis */
  yAxisMin?: number;
  /** (optional) show or hide legend */
  showLegend?: boolean;
  /** (optional) flag to force integer y-axis  (default is decimal format) */
  forceIntegerYAxis?: boolean;
  /** X-axis label text */
  xAxisLabel?: string;
  /** Y-axis label text */
  yAxisLabel?: string;
}

const ChartLegends = styled(Box)({
  display: "flex",
  margin: "auto",
  gap: 20,
});

const ChartLegend = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const ChartLegendBubble = styled(Box)({
  width: 20,
  height: 20,
  borderRadius: 10,
});

export const BarChartCard: PimoReactComponent<BarChartCardProps> = ({
  title,
  series,
  seriesLabel,
  average,
  averageLabel,
  categories,
  currency,
  cardProps = {},
  cardContentProps = {},
  yAxisMin,
  showLegend = true,
  forceIntegerYAxis = false,
  xAxisLabel,
  yAxisLabel,
}) => {
  const theme = useTheme();
  const seriesColor = theme.palette.primary.main;
  const averageLineColor = theme.palette.success.main;

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: xAxisLabel ?? "",
        offsetY: 0,
        style: {
          fontSize: "13px",
          fontWeight: "normal",
          color: theme.palette.text.primary,
        },
      },
      labels: {
        show: true,
        style: {
          colors: "grey.500",
        },
      },
    },
    yaxis: {
      forceNiceScale: true,
      min: yAxisMin ?? 0,
      max: forceIntegerYAxis
        ? Math.ceil(Math.max(...series, average ?? 0, 1) / 10) * 10
        : Math.max(...series, average ?? 0, 1),
      tickAmount: 10,
      decimalsInFloat: forceIntegerYAxis ? 0 : 2,
      labels: {
        formatter: (value) => {
          const formattedValue = forceIntegerYAxis ? Math.round(value) : value;
          return currency && formattedValue != null
            ? `${formattedValue.toLocaleString()} ${currency}`
            : `${formattedValue}`;
        },
        style: {
          colors: "grey.500",
        },
      },
      title: {
        text: yAxisLabel ?? "",
        offsetX: 0,
        style: {
          fontSize: "13px",
          fontWeight: "normal",
          color: theme.palette.text.primary,
        },
      },
    },
    legend: {
      labels: {
        colors: "grey.500",
      },
    },
    fill: {
      colors: [seriesColor],
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: "none",
        },
      },
    },
    annotations: {
      yaxis:
        average != null && averageLabel
          ? [
              {
                y: average,
                borderColor: averageLineColor,
                label: {
                  borderColor: averageLineColor,
                  style: { color: "#fff", background: averageLineColor },
                  text: `${
                    currency
                      ? `${averageLabel} is ${average.toLocaleString()} ${currency}`
                      : `${averageLabel} is ${average}`
                  }`,
                },
              },
            ]
          : [],
    },
  };

  return (
    <Card
      {...cardProps}
      data-testid="MonthlyBarRateChartContainer"
      title={title}
    >
      <CardContent {...cardContentProps} sx={{ marginX: 2.5 }}>
        <ReactApexChart
          type="bar"
          options={options}
          series={[
            {
              name: seriesLabel,
              data: series,
            },
          ]}
          height={260}
          className="apex-charts"
        />

        {showLegend && (
          <ChartLegends>
            <ChartLegend>
              <ChartLegendBubble sx={{ backgroundColor: seriesColor }} />
              <Typography>{seriesLabel}</Typography>
            </ChartLegend>
            {average != null && averageLabel && (
              <ChartLegend>
                <ChartLegendBubble sx={{ backgroundColor: averageLineColor }} />
                <Typography>{averageLabel}</Typography>
              </ChartLegend>
            )}
          </ChartLegends>
        )}
      </CardContent>
    </Card>
  );
};

export default BarChartCard;
