import type { App, Plugin, UniversalAppState } from "@pimo/pimo-app-builder";

import { APP_ROUTES } from "../constants";

// eslint-disable-next-line
export interface LandingPagePluginState {}

export class LandingPagePlugin<
  AppState extends UniversalAppState & LandingPagePluginState,
> implements Plugin<AppState, LandingPagePluginState>
{
  onRegister(app: App<AppState>): void {
    const view = app.createView({
      name: "Group Dashboard",
    });

    const route = app.createRoute({
      path: APP_ROUTES.dashboard,
      view,
    });

    route.on("load", () => app.navigate(APP_ROUTES.overview));
  }
}
