import { Add, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { OE, OESettingsPayload } from "crq-types";
import { useState } from "react";

export type OEManagerProps = {
  OEs: OE[];
};

type OEManagerEventNames = "OECreated" | "OEUpdated" | "OEDeleted";

export const ManageOEsSettingsPage: PimoReactComponent<
  OEManagerProps,
  OEManagerEventNames,
  OESettingsPayload
> = ({ OEs, fireEvent }) => {
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [formData, setFormData] = useState<OESettingsPayload>({
    name: "",
    cimid: "",
  });

  const handleOpenForm = () => {
    setFormData({ name: "", cimid: "" });
    setOpenForm(true);
  };

  const handleEdit = (oe: OE) => {
    if (!oe.id) {
      return;
    }
    setFormData({ id: oe.id, name: oe.name ?? "", cimid: oe.cimid ?? "" });
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSubmit = () => {
    if (formData.id != null) {
      fireEvent?.("OEUpdated", { ...formData });
    } else {
      fireEvent?.("OECreated", {
        ...formData,
      });
    }
    handleCloseForm();
  };

  const handleDelete = (oe: OE) => {
    setOpenDelete(true);
    setFormData({ id: oe.id, name: oe.name ?? "", cimid: oe.cimid ?? "" });
  };

  const handleConfirmDelete = () => {
    if (formData.id !== null) {
      fireEvent?.("OEDeleted", formData);
    }
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          padding: "15px 0",
        }}
      >
        <Typography variant="h5">Manage OEs</Typography>
        <Button variant="outlined" onClick={handleOpenForm} startIcon={<Add />}>
          New OE
        </Button>
      </Stack>

      {OEs.length === 0 && (
        <Box sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <Typography
            sx={{
              color: "#979797",
              fontSize: "30px",
              fontWeight: 500,
              display: "block",
              margin: "auto",
              marginTop: "150px",
              marginBottom: "150px",
            }}
          >
            You have not yet created any OEs
          </Typography>
        </Box>
      )}

      {OEs.length > 0 && (
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "700px",
            marginLeft: "24px",
          }}
        >
          <Table>
            <TableBody>
              {OEs.map((oe: OE, index) => (
                <TableRow key={oe.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell sx={{ width: "50%" }}>
                    <strong>{oe.name}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleEdit(oe)}
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(oe)}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        fullWidth
        maxWidth="md"
        sx={{ margin: 8 }}
        PaperProps={{ sx: { padding: 2 } }}
      >
        <DialogTitle
          sx={{
            color: "#000000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {formData.id != null ? "Edit OE" : "New OE"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <TextField
              label="OE Name*"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="OE ID*"
              value={formData.cimid}
              onChange={(e) =>
                setFormData({ ...formData, cimid: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", paddingRight: 2 }}>
          <Button onClick={handleCloseForm}>CLOSE</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            disabled={!formData.name || !formData.cimid}
            sx={{ ml: 2 }}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this OE?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
