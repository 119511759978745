import { STRAPI_URL } from "../env";

interface OESettingsPayload {
  name: string;
  cimid: string;
}

export function createOE(payload: OESettingsPayload) {
  const { name, cimid } = payload;

  return fetch(`${STRAPI_URL}/api/bff-oe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      name: name,
      cimid: cimid,
    }),
  });
}

export function updateOE(OEID: number, payload: OESettingsPayload) {
  const { name, cimid } = payload;

  return fetch(`${STRAPI_URL}/api/bff-oe/${OEID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      name: name,
      cimid: cimid,
    }),
  });
}
export function deleteOEs(OEID: number) {
  return fetch(`${STRAPI_URL}/api/bff-oe/${OEID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
}
